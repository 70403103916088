import {PageType} from "../../utils/PageType";
import React, {useEffect, useState} from "react";
import {UserInfo} from "../../dto/UserInfo";
import {toast} from "react-toastify";
import {Navigate} from "react-router-dom";
import Lottie from "lottie-react";
import redeemAnimation from "../../animations/lottie_redeem.json";
import {useTranslation} from "react-i18next";
import {CampaignService} from "../../services/CampaignService";
import {CampaignStatus} from "../../services/dtos/CampaignStatus";

interface RedeemComponentProps {
    setPageType: (pageType: PageType) => void;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
}

const RedeemComponent : React.FC<RedeemComponentProps> = ({ setPageType, userInfo, setUserInfo }) => {
    const { t } = useTranslation();

    const [redeemed, setRedeemed] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<{email?: string, password?: string}>();

    const redeem = async () => {

        try {
            const countryWithPlans = await CampaignService.getCampaignCountry(userInfo.country?.isoCode!);

            if(countryWithPlans.plans.length == 1) {
                const planId = countryWithPlans.plans[0].planId;

                const redeemedCampaign  = await CampaignService.redeemCampaign(userInfo.email, planId, userInfo.idToken);

                if(redeemedCampaign) {
                    setRedeemed(true);
                    setUserDetails(redeemedCampaign.user);
                }
            } else {
                toast.error("The following has still not been implemented yet. Contact Simly Developers.")
            }

        } catch (e) {
            toast.error((e as Error).message)
        }
    }

    useEffect(() => {
        redeem();
    }, []);

    return (
        <div className={"w-screen h-screen flex flex-col items-center"}>
            <div className={"mt-[10%] w-[85%] flex flex-col"}>
                <div className={"flex flex-row items-center"}>
                    <img className={"w-9 h-9"} src={userInfo.country?.countryImageURL} alt={userInfo.country?.name}/>
                    <p className={"ltr:ml-4 rtl:mr-4 font-gilroy-semibold text-2xl text-black"}>{userInfo.country?.name} eSIM</p>
                </div>

                {!redeemed ? <>
                        <div role="status" className={"mt-[15%] flex items-center justify-center"}>
                            <svg aria-hidden="true"
                                 className="inline w-16 h-16 mr-2 text-gray-200 animate-spin fill-[#E85929]"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"/>
                            </svg>
                            <span className="sr-only">{t('loading')}</span>
                        </div>
                    </>
                    : <>
                        <div className={"mt-[5%] flex flex-col w-full items-center justify-center"}>
                            <Lottie style={{width: 200}} animationData={redeemAnimation} loop={true}/>
                            <p className={"w-56 font-gilroy-semibold text-base text-[#999999] mt-[5%] text-center leading-none"}>
                                <bdi>{t('successVoilaText')}</bdi>
                            </p>
                            <p className={"font-gilroy-semibold text-base text-black text-center"}>{userInfo.email}</p>
                        </div>
                        <div className={"relative flex flex-col w-full bg-[#F9FAFB] rounded-xl mt-[10%] px-6 py-6"}>
                            <div
                                className={"flex items-center justify-center text-center absolute -top-5 -right-6 w-28 h-10 rounded-full bg-[#130F01]"}>
                                <p className={"font-gilroy-semibold text-white text-[0.5rem] w-[80%]"}>
                                    {t('screenshotPage')}
                                </p>
                            </div>
                            <p className={"text-[#222222] text-xl font-gilroy-bold"}>
                                <bdi>{t('stepsText')}</bdi>
                            </p>
                            <p className={"text-[#999999] text-base font-gilroy-semibold mt-[3.5%]"}>
                                <bdi>
                                    {t('stepOnePart')} <span
                                    className={"text-[#222222]"}>{t('stepOneApp')}</span>
                                    <span> {t('stepOneLoginCredentials')} </span>
                                    {
                                        userDetails?.email !== undefined
                                            ? (
                                                <>
                                                    <span
                                                        className={"text-[#222222]"}>{userDetails.email}</span> {t('stepOneEmail')}
                                                </>
                                            )
                                            : ''
                                    }
                                    {
                                        userDetails?.password !== undefined
                                            ? (
                                                <>
                                                    <span> {t('stepOneAnd')} </span>
                                                    <span
                                                        className={"text-[#222222]"}>{userDetails.password}</span> {t('stepOnePassword')}
                                                </>
                                            )
                                            : ''
                                    }
                                </bdi>
                            </p>
                            <p className={"text-[#999999] text-base font-gilroy-semibold mt-[3.5%]"}>
                                <bdi>{t('stepTwoPart')}</bdi>
                            </p>
                        </div>

                        <div
                            className={"mt-[5%] flex flex-row items-center justify-center w-full h-14 rounded-full bg-[#130F01] select-none"}
                            onClick={() => {
                                window.open("https://simlyapp.page.link/ALMOSAFERCAMPAIGN", "_self")
                            }}>
                            <p className="font-gilroy-bold text-lg text-white">
                                {t('downloadText')}
                            </p>
                        </div>


                        <div className={"flex w-full items-center justify-center my-[12%] gap-8"}>
                            <img src={"./assets/images/logo.svg"} alt={"simly-logo"} className={"pt-1 w-[5rem]"}/>
                            <img src={"./assets/images/almosafer.jpg"} alt={"almosafer-logo"}
                                 className={"w-[5rem]"}/>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default RedeemComponent;
